import React, { useState, useEffect } from 'react';
import api from '../../services/api';

const SubscriptionPlans = () => {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      const response = await api.get('/subscription-plans');
      setPlans(response.data);
    };
    fetchPlans();
  }, []);

  return (
    <div className="plans-container">
      <h1>Subscription Plans</h1>
      <ul>
        {plans.map((plan) => (
          <li key={plan.id}>
            <h2>{plan.name}</h2>
            <p>Price: ${plan.price}</p>
            <p>Duration: {plan.duration}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SubscriptionPlans;
