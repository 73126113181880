import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UserDashboard from './pages/Dashboard/UserDashboard';
import AdminDashboard from './pages/Admin/AdminDashboard';
import LoginPage from './pages/Auth/LoginPage';
import RegisterPage from './pages/Auth/RegisterPage';
import AgreementForm from './components/Agreements/AgreementForm';
import SubscriptionPlanManager from './pages/Admin/SubscriptionPlanManager';
import SubscriptionPlans from './components/Payments/SubscriptionPlans';

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Authentication */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />

        {/* Dashboard */}
        <Route path="/dashboard" element={<UserDashboard />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />

        {/* Agreements */}
        <Route path="/agreements/create" element={<AgreementForm />} />

        {/* Subscription Management */}
        <Route path="/admin/subscription-plans" element={<SubscriptionPlanManager />} />
        <Route path="/subscription-plans" element={<SubscriptionPlans />} />
      </Routes>
    </Router>
  );
};

export default App;
