import React from 'react';

const AdminDashboard = () => {
  return (
    <div className="admin-dashboard-container">
      <h1>Admin Dashboard</h1>
      <p>Manage users, templates, and subscriptions here.</p>
    </div>
  );
};

export default AdminDashboard;
